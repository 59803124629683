<template>
  <div class="users-list2">
    <el-table :data="users" border stripe style="width: 100%" header-cell-class-name="table-header">
      <el-table-column width="135" prop="name" label="姓名"> </el-table-column>
      <el-table-column width="105" prop="uname" label="用户名"> </el-table-column>
      <el-table-column width="50" label="性别">
        <template slot-scope="scope">
          <span>{{ scope.row.sex | sexFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column width="130" label="出生日期">
        <template slot-scope="scope">
          <span>{{ scope.row.birth | dateFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="籍贯">
        <template slot-scope="scope">
          <span>{{ [scope.row.pname+"省", scope.row.cname+"市", scope.row.region].join('-') }}</span>
        </template>
      </el-table-column>
      <el-table-column label="兴趣爱好">
        <template slot-scope="scope">
          <span>{{ scope.row.inters}}</span>
        </template>
      </el-table-column>
      <el-table-column label="自我介绍">
        <template slot-scope="scope">
          <span>{{ scope.row.intr}}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-row type="flex" style="margin-top:40px">
      <el-col :span="7">
      </el-col>
      <el-col :span="10">
        <div class="grid-content bg-purple-light">
          <!-- <el-pagination
            width:
            style="margin:0 auto;"
            :current-page="1"
            :page-size="5"
            :pager-count="5"
            layout="prev, pager, next"
            :total="100"
            @current-change="handleCurrentChange"
            >
          </el-pagination>   -->
          <Page :total="count" :current="pno" :page-size="psize" :transfer="true" @on-change="handleCurrentChange" />
        </div>
      </el-col>
      <el-col :span="7">
      </el-col>
    </el-row>
  </div>
</template>
<script>
import users from "../assets/js/apis/users"

export default {
  data() {
    return {
      users: [],
      count: 0,
      pcount:0,
      pno: 1,
      psize:10,
    };
  },
  mounted() {
    this.loadPage(this.pno, this.psize);
  },
  methods: {
    first(){
      this.pno=1;
      this.loadPage(this.pno, this.psize);
    },
    last(){
      this.pno=this.pcount;
      this.loadPage(this.pno, this.psize);
    },
    handleCurrentChange: function(pno){
      console.log(pno)  //点击第几页
      this.loadPage(pno, this.psize);
    },
    loadPage(pno, psize) {
      users.getUsers(pno,psize).then((result) => {
        console.log(result.data);
        this.users = result.data.data;
        this.count = parseInt(result.data.count);
        this.psize = parseInt(result.data.psize);
        this.pno=parseInt(result.data.pno);
        this.pcount=parseInt(result.data.pcount);
      });
    },
  },
};
</script>
<style>
  .div-first{
    text-align:right
  }
  .users-list2 .table-header{
    height: 50px;
    background: #0052D9 !important;
    color:#fff;
    text-align:center;
  }
  .grid-content{
    text-align:center;
  }
  .grid-content .ivu-page-item{
    margin-right:5px;
    width: 32px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
  }
  .grid-content .ivu-page-item>a{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #777777;
  }
  .grid-content .ivu-page-item-active{
    background: #146DFF;
    border-radius: 4px;
  }
  .grid-content .ivu-page-item-active>a{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 32px;
  }
  .grid-content .ivu-page-item:hover:not(.ivu-page-item-active){
    background: #EEEEEE;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
  }
  .grid-content .ivu-page-item:hover:not(.ivu-page-item-active)>a{
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #0052D9;
    line-height: 32px;
  }
  .grid-content .ivu-page-next, .grid-content .ivu-page-prev{
    width:32px; height:32px;
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
  }
  .grid-content .ivu-page-next>a>i:before, .grid-content .ivu-page-prev>a>i:before{
    line-height:32px;
    font-size:16px;
  }
  .grid-content .ivu-page-disabled{
    border:0;
    background: #F5F5F5;
    border-radius: 4px;
  }
</style>